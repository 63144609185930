import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";

import Loadable from "react-loadable";

import MainLayout from "./MainLayout";
import AppLayout from "./AppLayout";

// import NotFound from './NotFound';
import Page404 from "./Page404";
// import PrivateRoute from './PrivateRoute';
// import UploadRtas from '../containers/Rta/UploadRtas';

// Home components
const Home = Loadable({
  loader: () => import("../containers/Home/Home"),
  loading: () => <div></div>,
});
const PreLoginFundDetails = Loadable({
  loader: () => import("../containers/Home/FundDetails"),
  loading: () => <div></div>,
});
// const Welcome = Loadable({
//   loader: () => import('../containers/Welcome'),
//   loading: () => <div></div>,
// });

const LenDenUser = Loadable({
  loader: () => import("../containers/Login/LenDenUser"),
  loading: () => <div></div>,
});

//Investment component
// const Investment = Loadable({
//   loader: () => import('../containers/Investment/Investment'),
//   loading: () => <div></div>,
// });

const Signup = Loadable({
  loader: () => import("../containers/Signup/Signup"),
  loading: () => <div></div>,
});

const ForgotPassword = Loadable({
  loader: () => import("../containers/ForgotPassword/ForgotPassword"),
  loading: () => <div></div>,
});
const AboutUs = Loadable({
  loader: () => import("../containers/PreLogin/AboutUs"),
  loading: () => <div></div>,
});

const MutualFunds = Loadable({
  loader: () => import("../containers/PreLogin/MutualFunds"),
  loading: () => <div></div>,
});

const Insurance = Loadable({
  loader: () => import("../containers/PreLogin/Insurance"),
  loading: () => <div></div>,
});

const PortfolioManagement = Loadable({
  loader: () => import("../containers/PreLogin/PortfolioManagement"),
  loading: () => <div></div>,
});

const PortfolioManagementDescription = Loadable({
  loader: () => import("../containers/PreLogin/PortfolioManagementDescription"),
  loading: () => <div></div>,
});
// Auth components
const Login = Loadable({
  loader: () => import("../containers/Login/Login"),
  loading: () => <div></div>,
});

// const UserJourney = Loadable({
//   loader: () => import('../containers/AdminSetting/UserJourney'),
//   loading: () => <div></div>,
// });
const GoalMain = Loadable({
  loader: () => import("../containers/Goal/GoalMain"),
  loading: () => <div></div>,
});

const BuyThingMain = Loadable({
  loader: () => import("../containers/Goal/BuyThing/BuyThingMain"),
  loading: () => <div></div>,
});

const Experience = Loadable({
  loader: () => import("../containers/Goal/BuyThing/Experience"),
  loading: () => <div></div>,
});

const Freedom = Loadable({
  loader: () => import("../containers/Goal/BuyThing/Freedom"),
  loading: () => <div></div>,
});

// admin components
const AdminDashboard = Loadable({
  loader: () => import("../containers/AdminDashboard/AdminDashboard"),
  loading: () => <div></div>,
});
// const MyAdvisors = Loadable({
//   loader: () => import('../containers/MyAdvisors/MyAdvisors'),
//   loading: () => <div></div>,
// });
// const ClientMapping = Loadable({
//   loader: () => import('../containers/ClientMapping'),
//   loading: () => <div></div>,
// });

// advisor components
const AdvisorDashboard = Loadable({
  loader: () => import("../containers/AdvisorDashboard/AdvisorDashboard"),
  loading: () => <div></div>,
});

const MyClients = Loadable({
  loader: () => import("../containers/MyClient/MyClients"),
  loading: () => <div></div>,
});

//Advisor Fund Analytics
const fundAnalyticsMain = Loadable({
  loader: () => import("../containers/FundAnalytics/fundAnalyticsMain"),
  loading: () => <div></div>,
});

// const AdminSettingMain = Loadable({
//   loader: () => import('../containers/AdminSetting/AdminSettingMain'),
//   loading: () => <div></div>,
// });

const Details = Loadable({
  loader: () => import("../containers/RiskProfiling/Details"),
  loading: () => <div></div>,
});

// const ModelPortfolioParent = Loadable({
//   loader: () => import("../containers/ModelPortfolio/ModelPortfolioParent"),
//   loading: () => <div></div>,
// });
// const ips = Loadable({
//   loader: () => import("../containers/ModelPortfolioNew/ips"),
// });
const Review = Loadable({
  loader: () => import("../containers/ModelPortfolioNew/Review"),
  loading: () => <div></div>,
});
const ModelPortfolioParentNew = Loadable({
  loader: () => import("../containers/ModelPortfolioNew/ModelPortfolioParent"),
  loading: () => <div></div>,
});
const InvestmentsReport = Loadable({
  loader: () => import("../containers/InvestmentReport/InvestmentsReport"),
  loading: () => <div></div>,
});
const DownloadModalPortfolioUi = Loadable({
  loader: () => import("../containers/ModelPortfolioNew/DownloadModalPortfolioUi"),
  loading: () => <div></div>,
});

const Brief = Loadable({
  loader: () => import("../containers/RiskProfiling/Brief"),
  loading: () => <div></div>,
});

const MfAccount = Loadable({
  loader: () => import("../containers/CreateMfAccount/MfAccount"),
  loading: () => <div></div>,
});

const CreateAccounts = Loadable({
  loader: () => import("../containers/CreateMfAccount/CreateAccounts"),
  loading: () => <div></div>,
});

//client components
const ClientDashboard = Loadable({
  loader: () => import("../containers/ClientDashboard/NewClientDashboard"),
  loading: () => <div></div>,
});

const PremiumLounge = Loadable({
  loader: () => import("../containers/Primium/PremiumLounge"),
  loading: () => <div></div>,
});

const ExploreProducts = Loadable({
  loader: () => import("../containers/Primium/ExploreProducts"),
  loading: () => <div></div>,
});

// account creation
// const AccountCreation = Loadable({
//   loader: () => import('../containers/AccountCreation/AccountCreation'),
//   loading: () => <div></div>,
// });

const RtaAdmin = Loadable({
  loader: () => import("../containers/Rta/Rta"),
  loading: () => <div></div>,
});

// const DreamsMain = Loadable({
//   loader: () => import('../containers/ClientDashboard/DreamsMain'),
//   loading: () => <div></div>,
// });

// const PaymentPage = Loadable({
//   loader: () => import('../containers/ClientDashboard/PaymentPage'),
//   loading: () => <div></div>,
// });

// const IdeasMain = Loadable({
//   loader: () => import('../containers/ClientDashboard/IdeasMain'),
//   loading: () => <div></div>,
// });

// assets compoennt

// const AssetsMain = Loadable({
//   loader: () => import('../containers/Assets/assetsMain'),
//   loading: () => <div></div>,
// });

// Business Analytics

// const BusinessAnalyticsMain = Loadable({
//   loader: () => import('../containers/BusinessAnalytics/businessAnalyticsMain'),
//   loading: () => <div></div>,
// });

// transaction
// const Transaction = Loadable({
//   loader: () => import('../containers/Transaction/Transaction'),
//   loading: () => <div></div>,
// });

//holding components
const HoldingMain = Loadable({
  loader: () => import("../containers/Holding/holdingMain"),
  loading: () => <div></div>,
});

const P2P = Loadable({
  loader: () => import("../containers/P2p/NewP2P"),
  loading: () => <div></div>,
});

// on boarding
const OnBoarding = Loadable({
  loader: () => import("../containers/OnBoarding/OnBoarding"),
  loading: () => <div></div>,
});
const Rta = Loadable({
  loader: () => import("../containers/OnBoarding/Rta/Rta"),
  loading: () => <div></div>,
});
const Crm = Loadable({
  loader: () => import("../containers/OnBoarding/Crm/Crm"),
  loading: () => <div></div>,
});

const ClientStats = Loadable({
  loader: () => import("../containers/ClientStats/ClientStatsMain"),
  loading: () => <div></div>,
});

// cart
// const Cart = Loadable({
//   loader: () => import('../containers/Cart/Cart'),
//   loading: () => <div></div>,
// });

//orrder_management
const OrderManagement = Loadable({
  loader: () => import("../containers/OrderManagement/OrderManagement"),
  loading: () => <div></div>,
});

//Scoring
const Scoring = Loadable({
  loader: () => import("../containers/Scoring/Scoring"),
  loading: () => <div></div>,
});
const PortfolioAllocation = Loadable({
  loader: () => import("../containers/Scoring/PortfolioAllocation"),
  loading: () => <div></div>,
});

//notificationMain
// const NotificationMain = Loadable({
//   loader: () => import('../containers/Notification/notificationMain'),
//   loading: () => <div></div>,
// });

// const ProfileDetails = Loadable({
//   loader: () => import('../containers/ProfileDetails/profileDetails'),
//   loading: () => <div></div>,
// });

const Profile = Loadable({
  loader: () => import("../containers/ProfileDetails/Profile"),
  loading: () => <div></div>,
});

const PaymentProcess = Loadable({
  loader: () => import("../containers/PaymentProcess"),
  loading: () => <div></div>,
});

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/"
          render={(props) => (
            <MainLayout {...props}>
              <Home {...props} />
            </MainLayout>
          )}
        />
        <Route
          exact
          path="/aboutUs"
          render={(props) => (
            <MainLayout {...props}>
              <AboutUs {...props} />
            </MainLayout>
          )}
        />
        <Route
          exact
          path="/MutualFunds"
          render={(props) => (
            <MainLayout {...props}>
              <MutualFunds {...props} />
            </MainLayout>
          )}
        />
        <Route
          exact
          path="/Insurance"
          render={(props) => (
            <MainLayout {...props}>
              <Insurance {...props} />
            </MainLayout>
          )}
        />
        <Route
          exact
          path="/Portfolio"
          render={(props) => (
            <MainLayout {...props}>
              <PortfolioManagement {...props} />
            </MainLayout>
          )}
        />
        <Route
          exact
          path="/PortfolioManagement"
          render={(props) => (
            <MainLayout {...props}>
              <PortfolioManagementDescription {...props} />
            </MainLayout>
          )}
        />

        <Route
          exact
          path="/login"
          render={(props) => (
            <MainLayout {...props}>
              <Login {...props} />
            </MainLayout>
          )}
        />
        <Route
          exact
          path="/forgot_password"
          render={(props) => (
            <MainLayout {...props}>
              <ForgotPassword {...props} />
            </MainLayout>
          )}
        />
        <Route
          exact
          path="/Signup"
          render={(props) => (
            <MainLayout {...props}>
              <Signup {...props} />
            </MainLayout>
          )}
        />
        <Route
          path="/mutual_funds"
          render={(props) => (
            // <MainLayout {...props}>
            <PreLoginFundDetails {...props} />
            // </MainLayout>
          )}
        />
        {/* <Route exact path="/fin-health" render={props => <MainLayout {...props}><Brief {...props} /></MainLayout>} /> */}
        {/* <Route exact path="/User-Journey" render={props => <MainLayout {...props}><UserJourney {...props} /></MainLayout>} /> */}
        <Route path="/404" component={Page404} />
        <Route exact path="/download_ModalPortfolioUi" component={DownloadModalPortfolioUi} />
        <AppLayout>
          <Switch>
            {/* <Route exact path="/" component={Home} />
            <Route exact path="/login" component={Login} /> */}
            <Route path="/goal/dashboard" component={GoalMain} />
            <Route exact path="/goal" component={GoalMain} />
            {/* <Route exact path="/Welcome" component={Welcome} /> */}
            <Route exact path="/lenden_acc" component={LenDenUser} />
            <Route exact path="/fin-health" component={Brief} />
            <Route exact path="/goal/buy_things" component={BuyThingMain} />
            <Route exact path="/goal/experience" component={Experience} />
            <Route exact path="/goal/freedom" component={Freedom} />
            <Route exact path="/admin-dashboard" component={AdminDashboard} />
            <Route exact path="/advisor/dashboard" component={AdvisorDashboard} />
            {/*clientDashboarRoutes*/}
            <Route exact path="/client-dashboard" component={ClientDashboard} />
            <Route exact path="/Premium-Lounge" component={PremiumLounge} />
            <Route exact path="/Premium-lounge/Explore_products" component={ExploreProducts} />
            <Route exact path="/createAccounts" component={CreateAccounts} />
            <Route exact path="/onboarding" component={MfAccount} />

            {/* <Route exact path="/client-dashboard" component={ClientDashboard} />

            <Route exact path="/client-mapping" component={ClientMapping} /> */}
            {/* <PrivateRoute exact path="/dashboard" component={Dashboard} /> */}
            {/* <Route exact path="/dashboard" component={Dashboard} /> */}
            {/* <Route exact path="/kyc-search" component={KYCSearch} /> */}
            {/* <Route exact path="/aof" component={AOF} exact />          */}

            {/*clientDashboarRoutes*/}

            {/* rta routes */}
            {/* <Route exact path="/advisor/my-advisors" component={MyAdvisors} /> */}
            {/* <Route exact path="/advisor/aof" component={AccountCreation} />
            <Route exact path="/advisor/rta/rtas" component={UploadRtas} /> */}

            {/* Advisor Fund Analytics */}
            <Route path="/advisor/fund-analytics" component={fundAnalyticsMain} />
            <Route path="/advisor/my-clients" component={MyClients} />

            {/* <Route exact path="/advisor/fund-analytics" component={FundRanking} />
            <Route exact path="/advisor/Fund_Comparison" component={FundComparison} /> */}
            {/* Business Analytics */}
            {/* <Route path="/advisor/business-analytics" component={BusinessAnalyticsMain} /> */}

            <Route exact path="/advisor/onboarding/new" component={OnBoarding} />
            <Route exact path="/advisor/onboarding/rta" component={Rta} />
            <Route exact path="/advisor/onboarding/crm" component={Crm} />

            {/*onboarding new route*/}
            {/* <Route exact path="/advisor" render={() => <Redirect to="/advisor/onboarding/new" />} />
            <Route exact path="/advisor/onboarding/new" component={New} /> */}
            {/* <Route  path="/advisor/onboarding" component={OnBoarding} /> */}
            {/* admin stats */}
            <Route
              exact
              path="/client-stats"
              render={() => <Redirect to="/client-stats/client-mapping" />}
            />
            <Route exact path="/client-stats/client-mapping" component={ClientStats} />
            <Route path="/rta" component={RtaAdmin} />

            {/*admin rta*/}
            {/* <Route path="/rta" component={RtaAdmin} /> */}
            {/* <Route exact path="/rta" render={() => <Redirect to="/rta/processing" />} />
            <Route exact path="/rta/processing" component={ProcessingStats} />
            <Route exact path="/rta/recon" component={Recon} />
            <Route exact path="/rta/bank" component={Bank} />
            <Route exact path="/rta/address" component={Address} />
            <Route exact path="/rta/nomination" component={Nomination} /> */}

            {/* transaction routes */}
            {/* <Route path="/transaction" component={Transaction} /> */}
            {/* holding routes */}
            <Route path="/Investments" component={HoldingMain} />
            {/* investment routes */}
            {/* <Route path="/investment" component={Investment} /> */}
            {/* <Route exact path="/holding" render={() => <Redirect to="/holding/investment-summary" />} />
            <Route exact path="/holding/investment-summary" component={InvestmentSummary} />
            <Route exact path="/holding/analytical" component={Analytical} />
            <Route exact path="/holding/capital-gain" component={CapitalGain} />
            <Route exact path="/holding/units-consolidated" component={ConsolidatedReport} />
            <Route exact path="/holding/units-transaction" component={UnitTransaction} />
            <Route exact path="/holding/demo" component={Demo} />
            <Route exact path="/holding/HoldingReport" component={HoldingReport} /> */}

            {/* Client setting */}
            {/* <Route path="/admin-setting" component={AdminSettingMain} /> */}
            {/* <Route exact path="/advisor-setting" render={() => <Redirect to="/advisor-setting/fund-configuration" />} />
            <Route exact path="/advisor-setting/fund-configuration" component={FundConfiguration} />
            <Route exact path="/advisor-setting/Company" component={Company} />
            <Route exact path="/advisor-setting/BSE-Star-MF" component={BseStar} />
            <Route exact path="/advisor-setting/product-roi" component={ProductRoi} /> */}

            {/* Financial Health routes */}
            <Route
              exact
              path="/financial-health"
              render={() => <Redirect to="/financial-health/Brief" />}
            />
            <Route exact path="/financial-health/Brief" component={Brief} />
            {/* risk profiling routes */}
            <Route
              exact
              path="/risk-profiling"
              render={() => <Redirect to="/risk-profiling/Details" />}
            />
            <Route exact path="/risk-profiling/details" component={Details} />
            {/* <Route exact path="/model-portfolio" component={ModelPortfolioParent} /> */}
            <Route exact path="/investments-report" component={InvestmentsReport} />
            {/* <Route exact path="/investments-report" component={ModelPortfolioParentNew} /> */}
            <Route exact path="/model-portfolio" component={ModelPortfolioParentNew} />
            <Route exact path="/review" component={Review} />

            {/* <Route exact path="/download_ModalPortfolioUi" component={DownloadModalPortfolioUi}/> */}
            {/* <Route exact path="/risk-profiling" render={() => <Redirect to="/risk-profiling/Brief" />} />
            <Route exact path="/risk-profiling/Brief" component={Brief} />
            <Route exact path="/risk-profiling/Details" component={Details} /> */}

            {/* <Route exact path="/risk-profiling/Report" component={Report} /> */}

            {/* assets routes */}
            {/* <Route path="/assets" component={AssetsMain} />

            <Route path="/client-dashboard/main" component={DreamsMain} />
            <Route path="/client-dashboard/ideas-main" component={IdeasMain} />
            <Route path="/client-dashboard/payment" component={PaymentPage} />




            <Route exact path="/cart" component={Cart} />
            
            <Route exact path="/notification" component={NotificationMain} /> */}
            <Route exact path="/order-management" component={OrderManagement} />

            {/*Profile Details */}
            {/* <Route exact path="/profile-details" component={ProfileDetails} /> */}
            <Route exact path="/profile-details" component={Profile} />
            <Route exact path="/payment-process" component={PaymentProcess} />
            <Route exact path="/P2P" component={P2P} />
            <Route exact path="/scoring" component={Scoring} />
            <Route exact path="/portfolio_allocation" component={PortfolioAllocation} />

            {/* <Route path="*" component={NotFound} /> */}
            <Route path="*" render={() => <Redirect to="/404" />} />
          </Switch>
        </AppLayout>
      </Switch>
    </Router>
  );
};

export default Routes;
