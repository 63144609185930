import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isExpired } from '../utils/sessionManager';

const isLoggedIn = () => {
  if (isExpired('token')) {
    return true;
  } else {
    return false;
  }
};

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    isLoggedIn()
      ? <Component {...props} />
      : <Redirect to='/login' />
  )} />
);

export default PrivateRoute;
