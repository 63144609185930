export const CLIENT_NAV = [
  {
    key: "client_dashboard",
    path: "/client-dashboard",
    value: "DashBoard",
    isActive: true,
    isIcon: false,
    isSubscriptionModal: false,
    is_client: false,
  },
  // {
  //   key: 'risk_profiling',
  //   path: '/risk-profiling',
  //   value: 'Risk Profiling',
  // },
  // {
  //   key: 'financial_health',
  //   path: '/financial-health',
  //   value: 'Financial Health',
  // },

  // {
  //   key: 'investment',
  //   path: '/investment',
  //   value: 'Investment',
  // },
  {
    key: "goal",
    path: "/goal",
    value: "Goals",
    isActive: true,
    isIcon: false,
    isSubscriptionModal: false,
    is_client: false,
  },
  {
    key: "Kyc",
    path: "/createAccounts",
    value: "KYC",
    isActive: true,
    isIcon: false,
    isSubscriptionModal: false,
    is_client: false,
  },
  {
    key: "Investments",
    path: "/Investments",
    value: "My Investments",
    isActive: true,
    isIcon: false,
    isSubscriptionModal: false,
    is_client: false,
  },
  {
    key: "P2P",
    path: "/P2P",
    value: "P2P",
    isActive: true,
    isIcon: false,
    isSubscriptionModal: false,
    is_client: false,
  },
  // {
  //   key: 'Premium Lounge',
  //   path: '/Premium-Lounge',
  //   value: 'Premium Lounge',
  // },
  // {
  //   key: 'cart',
  //   path: '/cart',
  //   isIcon:true,
  //   icon:'fa fa-shopping-cart'
  // },
  {
    key: "order_management",
    path: "/order-management",
    isIcon: true,
    icon: "fa fa-tasks",
    hoverText: "Transaction History",
    isActive: true,
    isIcon: false,
    isSubscriptionModal: false,
    is_client: false,
  },
];

export const ADVISOR_NAV = [
  {
    key: "scoring",
    path: "/scoring",
    value: "Scoring",
  },
  {
    key: "rta",
    path: "/rta",
    value: "Migration",
  },
  {
    key: "portfolio_allocation",
    path: "/portfolio_allocation",
    value: "Portfolio Allocation",
  },
  {
    key: "client_stats",
    path: "/client-stats",
    value: "Client Stats",
  },
];

//ADMIN OR ADVISOR NAV
export const ADMIN_NAV = [];
export const SUBADVISOR_CLIENT_LOGIN_NAV = [
  {
    key: "advisor_dashboard_1",
    path: "/advisor/my-clients",
    value: "My DashBoard",
    isActive: true,
    isIcon: false,
    isSubscriptionModal: false,
    is_client: false,
  },
  {
    key: "client_dashboard",
    path: "/client-dashboard",
    value: "Client DashBoard",
    isActive: true,
    isIcon: false,
    isSubscriptionModal: false,
    is_client: false,
  },
  {
    key: "risk_profile",
    path: "/risk-profiling/Details",
    value: "Risk Profile",
    isActive: true,
    isIcon: false,
    isSubscriptionModal: false,
    is_client: false,
  },
  {
    key: "model_portfolio",
    path: "/model-portfolio",
    value: "Model Portfolio",
    isActive: true,
    isIcon: false,
    isSubscriptionModal: false,
    is_client: false,
  },
  {
    key: "review",
    path: "/review",
    value: "Review",
    isActive: true,
    isIcon: false,
    isSubscriptionModal: false,
    is_client: false,
  },
  {
    key: "investments",
    path: "/investments-report",
    value: "Investments",
    isActive: true,
    isIcon: false,
    isSubscriptionModal: false,
    is_client: false,
  },
];
export const SUB_ADVISOR_NAV = [
  // {
  //   key: "risk_profile",
  //   path: "/risk-profiling/Details",
  //   value: "Risk Profile",
  //   is_client: true,
  //   isActive: true,
  //   isIcon: false,
  //   isSubscriptionModal: false,
  // },
  // {
  //   key: "model_portfolio",
  //   path: "/model-portfolio",
  //   value: "Model Portfolio",
  //   is_client: true,
  //   isActive: true,
  //   isIcon: false,
  //   isSubscriptionModal: false,
  // },
  {
    key: "my_clients",
    path: "/advisor/my-clients",
    value: "My Clients",
    is_client: false,
    isActive: true,
    isIcon: false,
    isSubscriptionModal: false,
  },
  {
    key: "fund_analytics",
    path: "/advisor/fund-analytics",
    value: "Fund Analytics",
    is_client: false,
    isActive: true,
    isIcon: false,
    isSubscriptionModal: false,
  },
  {
    key: "onboarding",
    path: "/advisor/onboarding/new",
    value: "OnBoarding",
    isIcon: false,
    is_client: false,
    isActive: true,
    isSubscriptionModal: false,
  },
];

export const PreLoginNAV = [
  {
    key: "aboutUs",
    path: "/aboutUs",
    value: "ABOUT US",
  },
  {
    key: "MutualFunds",
    path: "/MutualFunds",
    value: "Mutual Funds",
  },
  {
    key: "Insurance",
    path: "/Insurance",
    value: "Insurance",
  },
  {
    key: "Portfolio",
    path: "/Portfolio",
    value: "Portfolio Management",
  },
  // {
  //   key: 'Reports',
  //   path: '/Reports',
  //   value: 'Reports',
  // },
  {
    key: "Login",
    path: "/Login",
    value: "Login",
  },
];
